var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"bg-white mt-4"},[_c('tabla-general',{attrs:{"data":_vm.dataTable,"alto":"calc(100vh - 365px)","size":"large","class-header":"text-general f-16"},on:{"filaFuncion":_vm.openDetalle}},[_c('template',{slot:"cabecera-izquierda"},[_c('div',{staticClass:"col-auto px-4 py-3 text-general f-18 f-600"},[_vm._v(" "+_vm._s(_vm.dataTable.length)+" pedidos pendientes por comisión ")])]),_c('el-table-column',{attrs:{"label":"Fecha de pago","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-general f-16 fr-light"},[_vm._v(_vm._s(_vm._f("helper-fecha")(row.created_at,'DD MMM Y')))])]}}])}),_c('el-table-column',{attrs:{"label":"Valor pagado","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-general f-16 fr-light"},[_vm._v(_vm._s(_vm.separadorNumero(row.valor_pagado)))])]}}])}),_c('el-table-column',{attrs:{"prop":"pedidos_pagados","label":"Pedidos pagados","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-general f-16 fr-light"},[_vm._v(_vm._s(row.cant_pedidos))])]}}])}),_c('el-table-column',{attrs:{"label":"Encargado","align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var row = ref.row;
return [_c('p',{staticClass:"text-general f-16 fr-light"},[_vm._v(_vm._s(row.encargado))])]}}])})],2),_c('el-pagination',{staticClass:"paginar-general d-middle-center",attrs:{"layout":"prev, pager, next","total":_vm.paginate.total,"page-size":_vm.paginate.per_page},on:{"current-change":_vm.getTabla}}),_c('modal-ver-detalle-comision',{ref:"refModalVerDetalleComision",attrs:{"tendero":_vm.tendero,"encargado":_vm.encargado}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }